<template>
  <div class="m-2 px-4 pb-2 survey-card">
    <template v-if="allows_crud">
      <div class="row m-1">
        <b-button
          size="sm"
          class="m-1"
          @click="$bvModal.show(`modal-update-scale-${likert_scale.id}`)"
          >Editar Escala de Likert</b-button
        >
        <b-button size="sm" class="m-1" @click="launchCreateOptionModal"
          >Agregar Opción</b-button
        >
        <b-button size="sm" class="m-1" @click="launchCreateAssertionModal"
          >Agregar Afirmación</b-button
        >
      </div>
    </template>
    <div class="row">
      {{ order ? order + ". " : "" }}{{ likert_scale.title }}
    </div>
    <div v-if="likert_scale.description" class="row m-1">
      {{ likert_scale.description }}
    </div>
    <div class="m-1">
      <template v-for="option in likert_options">
        <div :key="option.id" class="row m-1">
          <div style="width: 80%; text-align: left">
            <b>{{ option.short_name }}</b
            >: {{ option.text }}
          </div>
          <div style="width: 20%">
            <b-button size="sm" @click="launchUpdateOptionModal(option)"
              >Editar Opción</b-button
            >
          </div>
        </div>
      </template>
    </div>
    <div>
      <table class="likert-table">
        <thead>
          <tr>
            <th colspan="2">Afirmaciones</th>
            <template v-for="option in likert_options">
              <th :key="option.id" :title="option.text">
                {{ option.short_name }}
              </th>
            </template>
            <th v-if="allows_crud"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="assertion in likert_assertions">
            <tr :key="assertion.id">
              <td>{{ assertion.order }}</td>
              <td>{{ assertion.text }}</td>
              <template v-for="option in likert_options">
                <td :key="option.id" :title="option.text">
                  <b-form-checkbox></b-form-checkbox>
                </td>
              </template>
              <td v-if="allows_crud">
                <b-button
                  size="sm"
                  @click="launchUpdateAssertionModal(assertion)"
                  >Editar Afirmación</b-button
                >
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- Modals -->
    <b-modal
      :id="`modal-option-${likert_scale.id}`"
      :title="selected_option ? 'Editar Opción' : 'Crear Opción'"
      hide-footer
      size="md"
    >
      <LikertOptionForm
        :LikertOption="selected_option"
        :likert_scale_id="likert_scale.id"
        @created="slotCreatedOption"
        @updated="slotUpdatedOption"
      ></LikertOptionForm>
    </b-modal>
    <b-modal
      :id="`modal-assertion-${likert_scale.id}`"
      :title="selected_assertion ? 'Editar Afirmación' : 'Crear Afirmación'"
      hide-footer
      size="md"
    >
      <LikertAssertionForm
        :LikertAssertion="selected_assertion"
        :likert_scale_id="likert_scale.id"
        @created="slotCreatedAssertion"
        @updated="slotUpdatedAssertion"
      ></LikertAssertionForm>
    </b-modal>
    <b-modal
      :id="`modal-update-scale-${likert_scale.id}`"
      title="Editar Escala de Likert"
      hide-footer
      size="md"
    >
      <LikertScaleForm
        :LikertScale="likert_scale"
        :show_delete_button="true"
        @updated="slotUpdatedScale"
      ></LikertScaleForm>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "LikertScale",
  components: {
    LikertOptionForm: () => import("./LikertOptionForm"),
    LikertAssertionForm: () => import("./LikertAssertionForm"),
    LikertScaleForm: () => import("./LikertScaleForm"),
  },
  props: {
    LikertScale: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      likert_scale: this.LikertScale,
      likert_options: [],
      likert_assertions: [],
      selected_option: null,
      selected_assertion: null,
    };
  },
  methods: {
    fetchLikertOption() {
      this.$restful
        .Get(`/cideu/likert-option/?likert_scale=${this.likert_scale.id}`)
        .then((response) => {
          this.likert_options = response;
        });
    },
    fetchLikertAssertion() {
      this.$restful
        .Get(`/cideu/likert-assertion/?likert_scale=${this.likert_scale.id}`)
        .then((response) => {
          this.likert_assertions = response;
        });
    },
    launchCreateAssertionModal() {
      this.selected_assertion = null;
      this.$bvModal.show(`modal-assertion-${this.likert_scale.id}`);
    },
    launchUpdateAssertionModal(assertion) {
      this.selected_assertion = assertion;
      this.$bvModal.show(`modal-assertion-${this.likert_scale.id}`);
    },
    launchCreateOptionModal() {
      this.selected_option = null;
      this.$bvModal.show(`modal-option-${this.likert_scale.id}`);
    },
    launchUpdateOptionModal(option) {
      this.selected_option = option;
      this.$bvModal.show(`modal-option-${this.likert_scale.id}`);
    },
    slotCreatedOption(option) {
      this.likert_options.push(option);
      this.$bvModal.hide(`modal-option-${this.likert_scale.id}`);
    },
    slotUpdatedOption(option) {
      const index = this.likert_options.findIndex((x) => x.id == option.id);
      if (index != -1) {
        this.likert_options.splice(index, 1, option);
      }
      this.$bvModal.hide(`modal-option-${this.likert_scale.id}`);
    },
    slotCreatedAssertion(assertion) {
      this.likert_assertions.push(assertion);
      this.$bvModal.hide(`modal-assertion-${this.likert_scale.id}`);
    },
    slotUpdatedAssertion(assertion) {
      const index = this.likert_assertions.findIndex(
        (x) => x.id == assertion.id
      );
      if (index != -1) {
        this.likert_assertions.splice(index, 1, assertion);
      }
      this.$bvModal.hide(`modal-assertion-${this.likert_scale.id}`);
    },
    slotUpdatedScale(scale) {
      this.$bvModal.hide(`modal-update-scale-${this.likert_scale.id}`);
      this.likert_scale = scale;
      this.$emit("updated", scale);
    },
  },
  created() {
    this.fetchLikertOption();
    this.fetchLikertAssertion();
  },
};
</script>

<style scoped>
.likert-table {
  border: 1px solid black;
  width: 100%;
}
tr,
td {
  border: 1px solid black;
}
</style>